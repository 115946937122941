<style>
    /* Set the page size to A4 and the orientation to landscape */
    @page {
        size: A4 landscape;
    }

    /* Set the font size to 12px for printing */
    @media print {
        body {
            font-size: 12px;
        }
    }

    #logo {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 200px;
        height: 100px;
    }

    #title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #no-border {
        border: none;
    }

    td, th {
        border: 2px solid #000000;
        text-align: left;
        padding: 8px;
    }

    .remove_border tr th{
        border: none;
    }

    #table-list {
        margin-top: 80px;
    }

    #info {
        margin-top: 20px;
        width: 40%;
        float: left;
        margin-bottom: 20px;
    }

    .space-for-logo {
        width: 200px;
        height: 100px;
    }
</style>

<template>
    <div>
        <!-- <img :src="logoSDN" id="logo" /> -->
        <p id="title">Work Order Execution - Picking Plan</p>
        <div class="space-for-logo"></div>
        <div id="info">
            <table class="remove_border">
                <tr>
                    <th>Work Order ID</th>
                    <th>:</th>
                    <th>{{ woCode }}</th>
                </tr>
                <tr>
                    <th>Work Order Description</th>
                    <th>:</th>
                    <th>{{ description }}</th>
                </tr>
                <tr>
                    <th>Bill Of Material ID</th>
                    <th>:</th>
                    <th>{{ bomCode }}</th>
                </tr>
                <tr>
                    <th>Warehouse</th>
                    <th>:</th>
                    <th>{{ warehouseName }}</th>
                </tr>
            </table>
        </div>
        <table id="table-list">
            <thead>
                <tr>
                    <th style="text-align: center" colspan="7">
                        Detail of Plan Document
                    </th>
                </tr>
                <tr></tr>
                <tr>
                    <th style="vertical-align: middle; text-align: center;" rowspan="2">SKU Code</th>
                    <th style="vertical-align: middle; text-align: center;" rowspan="2">SKU Description</th>
                    <th style="vertical-align: middle; text-align: center;" rowspan="2">HU</th>
                    <th style="vertical-align: middle; text-align: center;" rowspan="2">Qty</th>
                    <th style="text-align: center;" colspan="3">Suggestion</th>
                </tr>
                <tr>
                    <th style="text-align: center;">Storage Area</th>
                    <th style="text-align: center;">Batch Number</th>
                    <th style="text-align: center;">Expired Date</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(tr, index) in skuLines">
                    <tr :key="index">
                        <td class="align-top" 
                            :rowspan="skuLines[index].suggestion_line.length"
                        >{{ tr.sku_code }}</td>
                        <td class="align-top" 
                            :rowspan="skuLines[index].suggestion_line.length"
                        >{{ tr.item_name }}</td>
                        <td class="align-top" 
                            :rowspan="skuLines[index].suggestion_line.length"
                        >{{ tr.unit }}</td>
                        <td class="align-top" 
                            :rowspan="skuLines[index].suggestion_line.length"
                        >{{ tr.qty }}</td>
                        <td>{{ skuLines[index].suggestion_line[0].storage_code }}</td>
                        <td>{{ skuLines[index].suggestion_line[0].batch }}</td>
                        <td>{{ formatDate(skuLines[index].suggestion_line[0].exp_date) }}</td>
                    </tr>

                    <template v-for="(trx, indexSuggestion) in skuLines[index].suggestion_line">
                        <tr :key="index + '-' + indexSuggestion" v-if="indexSuggestion > 0">
                            <td class="td">{{ trx.storage_code }}</td>
                            <td class="td">{{ trx.batch }}</td>
                            <td class="td">{{ formatDate(trx.exp_date) }}</td>
                        </tr>
                    </template>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from "moment-timezone";
import logoSDN from "../../../assets/images/logo/logo-sdn.png";
export default {
    name: "PrintPdf",
    data() {
        return {
            id: null,
            table: this.tableDefaultState(),
            woCode: null,
            description: null,
            bomCode: null,
            warehouseName: null,
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    unit: null,
                    qty: 0,
                    suggestion_line: [
                        {
                            suggestion_id: 0,
                            item_unit_id: 0,
                            sku_code: null,
                            item_name: null,
                            storage_code: null,
                            batch: null,
                            exp_date: null,
                        }
                    ]
                }
            ],
            logoSDN: logoSDN,
            showInfo: false,
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            console.log(this.id)
            this.print();
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
            };
        },
        async print() {
            await this.getData()
            await window.print()
        },
        async getData() {
            this.$vs.loading();
            let resp = await this.$http.get(
                "/api/wms/v1/work-order-execution/detail-picking-plan/" + this.id
            );
            if (resp.code == 200) {
                this.woCode = resp.data.wo_code
                this.description = resp.data.wo_description
                this.bomCode = resp.data.bom_code
                this.warehouseName = resp.data.warehouse_name
                this.skuLines = resp.data.sku_lines
                this.table.data = resp.data
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        },
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>